import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { filter, map, Observable, tap } from 'rxjs';
import { UserFacade } from '@app/store/user';

export const notAuthenticated: CanMatchFn = (): Observable<boolean> => {
    const userFacade = inject(UserFacade);

    return userFacade.loaded$.pipe(
        tap((loaded) => {
            if (loaded === null) {
                userFacade.getUser();
            }
        }),
        filter((loaded) => loaded !== null),
        map((loaded) => loaded !== true),
    );
};
